import { IOption, MultiSelect } from '@isdd/idsk-ui-kit/index'
import { GroupWithIdentities } from '@isdd/metais-common/api/generated/iam-swagger'
import { ApiVoteActor } from '@isdd/metais-common/api/generated/standards-swagger'
import { ElementToScrollTo } from '@isdd/metais-common/components/element-to-scroll-to/ElementToScrollTo'
import { QueryFeedback } from '@isdd/metais-common/index'
import { useEffect, useMemo } from 'react'
import { FieldErrors, UseFormClearErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GROUP_ROLES } from '@isdd/metais-common/constants'

import { IVoteEditForm } from '@/components/views/standardization/votes/VoteComposeForm/VoteComposeFormView'

export interface IError {
    invitedUsers: ApiVoteActor[]
}

interface ISelectVoteActors {
    userList?: GroupWithIdentities[]
    isLoading?: boolean
    isError?: boolean
    defaultValues?: ApiVoteActor[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue: UseFormSetValue<any>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    watch: UseFormWatch<any>
    errors: FieldErrors<IError>
    clearErrors: UseFormClearErrors<IVoteEditForm>
}

export enum VoteActorsEnum {
    INVITED_GROUPS = 'invitedGroups',
    INVITED_USERS = 'invitedUsers',
}

const getInvitedGroupsOptions = (groupWithIdentitiesDataArray: GroupWithIdentities[] | undefined): IOption<string>[] => {
    return (
        groupWithIdentitiesDataArray?.map((ig) => {
            return { value: ig.group?.uuid ?? '', label: ig.group?.name ?? '' }
        }) ?? []
    )
}

export interface LoginExtend extends IOption<string> {
    login?: string
    groupId?: string
}

export const getDefaultSelectedUsers = (voteActorArray: ApiVoteActor[] | undefined, invitedUsersOptions: LoginExtend[]): string[] => {
    const defaultSelectedUsers = voteActorArray?.map<string>((va) => {
        const resultVal = invitedUsersOptions.find((iuo) => iuo.login == va.userId && iuo.groupId == va.groupId)?.value
        return resultVal ?? ''
    })
    return (
        defaultSelectedUsers?.filter((dsa) => {
            return dsa != ''
        }) ?? []
    )
}

export const findUser = (id: string, groups?: GroupWithIdentities[]) => {
    if (!groups) return
    for (let i = 0; i < groups?.length; i++) {
        const group = groups[i]
        const usertmp = group.identities?.find((o) => `${o.identity?.uuid}-${group.group?.uuid}` === id)
        if (usertmp)
            return {
                id: `${usertmp.identity?.uuid}-${group.group?.uuid}`,
                userId: usertmp.identity?.login,
                groupId: group.group?.uuid,
                userRoleId: usertmp.gids?.[0].roleId,
                userOrgId: usertmp.gids?.[0].orgId,
            }
    }
}

export const SelectVoteActors = ({
    defaultValues,
    setValue,
    watch,
    errors,
    userList,
    isLoading = false,
    isError = false,
    clearErrors,
}: ISelectVoteActors) => {
    const { t } = useTranslation()
    const watchInvitedUsers: (
        | {
              id: string | undefined
              userId: string | undefined
              groupId: string | undefined
              userRoleId: string | undefined
              userOrgId: string | undefined
          }
        | undefined
    )[] = watch(VoteActorsEnum.INVITED_USERS)
    const watchInvitedGroups: string[] | undefined = watch(VoteActorsEnum.INVITED_GROUPS)
    const invitedGroupsOptions: IOption<string>[] = useMemo(() => {
        return getInvitedGroupsOptions(userList)
    }, [userList])

    const invitedUsersOptions = useMemo(
        () =>
            userList?.flatMap((group) =>
                (group?.identities || []).map((actor) => ({
                    value: `${actor?.identity?.uuid}-${group.group?.uuid}`,
                    label: `${actor?.identity?.displayName} (${group?.group?.name})`,
                    login: actor.identity?.login,
                    userId: actor?.identity?.uuid,
                    groupId: group.group?.uuid,
                })),
            ) || [],
        [userList],
    )

    const defaultSelectedUsers = getDefaultSelectedUsers(defaultValues, invitedUsersOptions) ?? []

    const selectedInvitedUsers = watchInvitedUsers?.map((user) => `${user?.id}` || '') ?? []

    const onUserChange = (value: string[]) => {
        const newActors = value.map((id) => {
            const user = findUser(id, userList)
            if (user) return user
        })
        setValue(VoteActorsEnum.INVITED_USERS, newActors)

        const groupedActors: {
            [key: string]: string[]
        } = {}
        newActors.forEach((actor) => {
            if (actor?.groupId && actor?.userId) groupedActors[actor.groupId] = [...(groupedActors[actor.groupId] || []), actor.userId]
        })
        const selectedGroups = []
        for (const key in groupedActors) {
            if (Object.hasOwn(groupedActors, key)) {
                const group = userList?.find((item) => item?.group?.uuid === key)
                if (group?.identities?.length === groupedActors[key].length) {
                    selectedGroups.push(key)
                }
            }
        }
        setValue(VoteActorsEnum.INVITED_GROUPS, selectedGroups)
    }

    const onGroupChange = (values: string[]) => {
        const prevGroups = [...(watchInvitedGroups || [])]
        setValue(VoteActorsEnum.INVITED_GROUPS, values)
        const newActors = [...(watchInvitedUsers || [])]
        if (prevGroups.length > values.length) {
            const removedGroupId = prevGroups.find((id) => !values.includes(id))
            setValue(
                VoteActorsEnum.INVITED_USERS,
                newActors.filter((user) => user?.groupId !== removedGroupId),
            )
        } else {
            values.forEach((groupId) => {
                const group = userList?.find((o) => o?.group?.uuid === groupId)
                group?.identities?.forEach((identity) => {
                    if (
                        !newActors.some((o) => o?.id === `${identity.identity?.uuid}-${group.group?.uuid}`) &&
                        identity?.gids?.[0].roleName !== GROUP_ROLES.STD_PSPO
                    ) {
                        newActors.push({
                            id: `${identity.identity?.uuid}-${group.group?.uuid}`,
                            userId: identity.identity?.login,
                            groupId: group.group?.uuid,
                            userRoleId: identity.gids?.[0].roleId,
                            userOrgId: identity.gids?.[0].orgId,
                        })
                    }
                })
            })
            setValue(VoteActorsEnum.INVITED_USERS, newActors)
        }
    }

    useEffect(() => {
        setValue(
            VoteActorsEnum.INVITED_USERS,
            defaultSelectedUsers.map((userLogin) => findUser(userLogin)),
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userList])

    if (!invitedGroupsOptions) return null

    return (
        <QueryFeedback loading={isLoading} error={isError} withChildren>
            <MultiSelect
                key={VoteActorsEnum.INVITED_GROUPS}
                name={VoteActorsEnum.INVITED_GROUPS}
                label={t('votes.voteEdit.invited.invitedGroups')}
                placeholder={t('filter.chooseValue')}
                options={invitedGroupsOptions}
                onChange={onGroupChange}
                value={watchInvitedGroups}
                clearErrors={clearErrors}
            />
            <ElementToScrollTo trigger={!!errors[VoteActorsEnum.INVITED_USERS]?.message} manualScroll>
                <MultiSelect
                    key={VoteActorsEnum.INVITED_USERS}
                    name={VoteActorsEnum.INVITED_USERS}
                    label={t('votes.voteEdit.invited.invitedUsers')}
                    placeholder={t('filter.chooseValue')}
                    options={invitedUsersOptions as IOption<string>[]}
                    onChange={onUserChange}
                    value={selectedInvitedUsers}
                    clearErrors={clearErrors}
                    error={errors[VoteActorsEnum.INVITED_USERS]?.message}
                />
            </ElementToScrollTo>
        </QueryFeedback>
    )
}
