import { FILTER_KEY } from '@isdd/metais-common/componentHelpers'

export const BASE_PAGE_NUMBER = 1
export const BASE_PAGE_SIZE = 10
export const urlBase = 'https://metais.vicepremier.gov.sk'
export const DMS_DOWNLOAD_FILE = `${import.meta.env.VITE_REST_CLIENT_DMS_TARGET_URL}/file/`
export const DMS_DOWNLOAD_BASE = `${import.meta.env.VITE_REST_CLIENT_DMS_TARGET_URL}/file`
export enum GET_ENUM {
    KATEGORIA_OSOBA = 'KATEGORIA_OSOBA',
    TYP_OSOBY = 'TYP_OSOBY',
    ZDROJ = 'ZDROJ',
    TYP_REPLIKACIE = 'TYP_REPLIKACIE',
    MERNA_JEDNOTKA = 'MERNA_JEDNOTKA',
    STAV_ISVS = 'STAV_ISVS',
    TYP_ISVS = 'TYP_ISVS',
    LIVE = 'LIVE',
    DOWN = 'DOWN',
    MAINTENANCE = 'MAINTENANCE',
}

export enum ATTRIBUTE_NAME {
    Gen_Profil_nazov = 'Gen_Profil_nazov',
    EA_Profil_PO_kategoria_osoby = 'EA_Profil_PO_kategoria_osoby',
    EA_Profil_PO_typ_osoby = 'EA_Profil_PO_typ_osoby',
    Gen_Profil_anglicky_nazov = 'Gen_Profil_anglicky_nazov',
    Gen_Profil_popis = 'Gen_Profil_popis',
    Gen_Profil_anglicky_popis = 'Gen_Profil_anglicky_popis',
    Gen_Profil_zdroj = 'Gen_Profil_zdroj',
    Gen_Profil_kod_metais = 'Gen_Profil_kod_metais',
    Gen_Profil_ref_id = 'Gen_Profil_ref_id',
    Gen_Profil_Rel = 'Gen_Profil_Rel',
    EA_Profil_PO_webove_sidlo = 'EA_Profil_PO_webove_sidlo',
    Gen_Profil_poznamka = 'Gen_Profil_poznamka',
    EA_Profil_Rel = 'EA_Profil_Rel',
    EA_Profil_PO_ico = 'EA_Profil_PO_ico',
    EA_Profil_PO_psc = 'EA_Profil_PO_psc',
    EA_Profil_PO_obec = 'EA_Profil_PO_obec',
    EA_Profil_PO_ulica = 'EA_Profil_PO_ulica',
    EA_Profil_PO_cislo = 'EA_Profil_PO_cislo',
    EA_Profil_PO_okres = 'EA_Profil_PO_okres',
    EA_Profil_Projekt_schvalovaci_proces = 'EA_Profil_Projekt_schvalovaci_proces',
    EA_Profil_PO_je_kapitola = 'EA_Profil_PO_je_kapitola',
    EA_Profil_Projekt_status = 'EA_Profil_Projekt_status',
    EA_Profil_Projekt_program = 'EA_Profil_Projekt_program',
    EA_Profil_Projekt_typ_investicie = 'EA_Profil_Projekt_typ_investicie',
    EA_Profil_PO_schranka_ovm_uri = 'EA_Profil_PO_schranka_ovm_uri',
    EA_Profil_PO_schranka_upvs_name = 'EA_Profil_PO_schranka_upvs_name',
    Financny_Profil_Projekt_rocne_naklady = 'Financny_Profil_Projekt_rocne_naklady',
    Financny_Profil_Projekt_suma_vydavkov = 'Financny_Profil_Projekt_suma_vydavkov',
    Profil_Rel_FazaZivotnehoCyklu = 'Profil_Rel_FazaZivotnehoCyklu',
    IKT_Profil_Schvalenie_stav_migracie = 'IKT_Profil_Schvalenie_stav_migracie',
    Financny_Profil_Projekt_schvalene_rocne_naklady = 'Financny_Profil_Projekt_schvalene_rocne_naklady',
    Financny_Profil_Projekt_schvaleny_rozpocet = 'Financny_Profil_Projekt_schvaleny_rozpocet',
    Gen_Profil_EA_skrateny_nazov = 'Gen_Profil_EA_skrateny_nazov',
    Gen_Profil_EA_popis_referencie = 'Gen_Profil_EA_popis_referencie',
    Gen_Profil_EA_odkaz_na_referenciu = 'Gen_Profil_EA_odkaz_na_referenciu',
    Gen_Profil_EA_typ_replikacie = 'Gen_Profil_EA_typ_replikacie',
    Gen_Profil_EA_pocet_replikacii = 'Gen_Profil_EA_pocet_replikacii',
    ReferenceRegisterHistory_Profile_stav = 'ReferenceRegisterHistory_Profile_stav',
    ReferenceRegisterHistory_Profile_popis = 'ReferenceRegisterHistory_Profile_popis',
    ReferenceRegisterHistory_Profile_prilohy = 'ReferenceRegisterHistory_Profile_prilohy',
    ReferenceRegister_Profile_name = 'ReferenceRegister_Profile_name',
    ISVS_Name = 'isvsName',
    standardRequestState = 'standardRequestState',
    actionDescription = 'actionDescription',
    requestChannel = 'requestChannel',
    createdAt = 'createdAt',
    lastModifiedAt = 'lastModifiedAt',
    name = 'name',
    fullName = 'fullName',
    email = 'email',
    description = 'description',
    relatedDocuments = 'relatedDocuments',
    Profil_KRIS_stav_kris = 'Profil_KRIS_stav_kris',
    Profil_Ciel_kategoria = 'Profil_Ciel_kategoria',
    Profil_KRIS_Zavazok_ciele_principy_stav = 'Profil_KRIS_Zavazok_ciele_principy_stav',
    Gui_Profil_DIZ_konzumujuci_projekt = 'Gui_Profil_DIZ_konzumujuci_projekt',
    Gui_Profil_DIZ_poskytujuci_projekt = 'Gui_Profil_DIZ_poskytujuci_projekt',
    Integracia_Profil_Integracia_stav_diz = 'Integracia_Profil_Integracia_stav_diz',
    Profil_URIKatalog_uri = 'Profil_URIKatalog_uri',
    Profil_URIDataset_uri_datasetu = 'Profil_URIDataset_uri_datasetu',
    Profil_Individuum_zaklad_uri = 'Profil_Individuum_zaklad_uri',
    Profil_Individuum_historicky_kod = 'Profil_Individuum_historicky_kod',
    Profil_URIKatalog_historicky_kod = 'Profil_URIKatalog_historicky_kod',
    Profil_URIDataset_historicky_kod = 'Profil_URIDataset_historicky_kod',
    Profil_DatovyPrvok_historicky_kod = 'Profil_DatovyPrvok_historicky_kod',
    Profil_DatovyPrvok_zaciatok_ucinnosti = 'Profil_DatovyPrvok_zaciatok_ucinnosti',
    Profil_Individuum_platne_od = 'Profil_Individuum_platne_od',
    Profil_URIKatalog_platne_od = 'Profil_URIKatalog_platne_od',
    Profil_URIDataset_platne_od = 'Profil_URIDataset_platne_od',
    Profil_DatovyPrvok_koniec_ucinnosti = 'Profil_DatovyPrvok_koniec_ucinnosti',
    Profil_Individuum_platne_do = 'Profil_Individuum_platne_do',
    Profil_URIKatalog_platne_do = 'Profil_URIKatalog_platne_do',
    Profil_URIDataset_platne_do = 'Profil_URIDataset_platne_do',
    Profil_DatovyPrvok_kod_datoveho_prvku = 'Profil_DatovyPrvok_kod_datoveho_prvku',
    Profil_DatovyPrvok_typ_datoveho_prvku = 'Profil_DatovyPrvok_typ_datoveho_prvku',
    Profil_Individuum_kod = 'Profil_Individuum_kod',
    Profil_Individuum_versions = 'Profil_Individuum_versions',
    Gen_Profil_RefID_stav_registracie = 'Gen_Profil_RefID_stav_registracie',
    Profil_Kontrakt_faza = 'Profil_Kontrakt_faza',
    Profil_Kontrakt_identifikator = 'Profil_Kontrakt_identifikator',
    Profil_Kontrakt_podpora_forma_kontaktu = 'Profil_Kontrakt_podpora_forma_kontaktu',
    Profil_Kontrakt_podpora_forma_kontaktu_mimo_prevadzky = 'Profil_Kontrakt_podpora_forma_kontaktu_mimo_prevadzky',
    Profil_Kontrakt_podpora_prevadzkova_doba = 'Profil_Kontrakt_podpora_prevadzkova_doba',
    Profil_Kontrakt_podpora_instrukcie_kontaktovania = 'Profil_Kontrakt_podpora_instrukcie_kontaktovania',
    Profil_Kontrakt_podpora_max_odozva_mimo_prevadzky = 'Profil_Kontrakt_podpora_max_odozva_mimo_prevadzky',
    Profil_Kontrakt_podpora_max_odozva_pocas_prevadzky = 'Profil_Kontrakt_podpora_max_odozva_pocas_prevadzky',
    Profil_Skolenie = 'Profil_Skolenie',
    Profil_Skolenie_pocet_volnych_miest = 'Profil_Skolenie_pocet_volnych_miest',
    Profil_Skolenie_pocet_miest = 'Profil_Skolenie_pocet_miest',
    Profil_Skolenie_zaciatok = 'Profil_Skolenie_zaciatok',
    Gen_Profil_Rel_kod_metais = 'Gen_Profil_Rel_kod_metais',
    Profil_ObjektEvidencie_otvorenost_udajov = 'Profil_ObjektEvidencie_otvorenost_udajov',
    Profil_ObjektEvidencie_dovod = 'Profil_ObjektEvidencie_dovod',
    Profil_ObjektEvidencie_eu_ref_id = 'Profil_ObjektEvidencie_eu_ref_id',
    Profil_ObjektEvidencie_jednotny_URI = 'Profil_ObjektEvidencie_jednotny_URI',
    Profil_ObjektEvidencie_nazov = 'Profil_ObjektEvidencie_nazov',
    Profil_ObjektEvidencie_rok = 'Profil_ObjektEvidencie_rok',
    Profil_ObjektEvidencie_cislo = 'Profil_ObjektEvidencie_cislo',
    Profil_ObjektEvidencie_paragraf = 'Profil_ObjektEvidencie_paragraf',
    Profil_ObjektEvidencie_odsek = 'Profil_ObjektEvidencie_odsek',
    Profil_ObjektEvidencie_pismeno = 'Profil_ObjektEvidencie_pismeno',
    Profil_ObjektEvidencie_bod = 'Profil_ObjektEvidencie_bod',
    Profil_ObjektEvidencie_zdroj = 'Profil_ObjektEvidencie_zdroj',
    Profil_ObjektEvidencie_popis = 'Profil_ObjektEvidencie_popis',
    Profil_ObjektEvidencie_eli_uri = 'Profil_ObjektEvidencie_eli_uri',
    Profil_AtributObjektuEvidencie_nazov = 'Profil_AtributObjektuEvidencie_nazov',
    Profil_AtributObjektuEvidencie_paragraf = 'Profil_AtributObjektuEvidencie_paragraf',
    Profil_AtributObjektuEvidencie_cislo = 'Profil_AtributObjektuEvidencie_cislo',
    Profil_AtributObjektuEvidencie_rok = 'Profil_AtributObjektuEvidencie_rok',
    Profil_AtributObjektuEvidencie_odsek = 'Profil_AtributObjektuEvidencie_odsek',
    Profil_AtributObjektuEvidencie_pismeno = 'Profil_AtributObjektuEvidencie_pismeno',
    Profil_AtributObjektuEvidencie_bod = 'Profil_AtributObjektuEvidencie_bod',
    Profil_AtributObjektuEvidencie_zdroj = 'Profil_AtributObjektuEvidencie_zdroj',
    Profil_AtributObjektuEvidencie_eli_uri = 'Profil_AtributObjektuEvidencie_eli_uri',
    Profil_AtributObjektuEvidencie_jednotny_URI = 'Profil_AtributObjektuEvidencie_jednotny_URI',
    Profil_AtributObjektuEvidencie_eu_ref_id = 'Profil_AtributObjektuEvidencie_eu_ref_id',
    Profil_OEOpravnenie_atribut_objektu_evidencie = 'Profil_OEOpravnenie_atribut_objektu_evidencie',
    Profil_OEOpravnenie_typ_opravnenia = 'Profil_OEOpravnenie_typ_opravnenia',
    Profil_OEOpravnenie_pravny_zaklad_kod = 'Profil_OEOpravnenie_pravny_zaklad_kod',
    Profil_OEOpravnenie_pravny_zaklad = 'Profil_OEOpravnenie_pravny_zaklad',
    Profil_OEOpravnenie_pravny_ucel = 'Profil_OEOpravnenie_pravny_ucel',
    Profil_OsobitnyPostup_suvisiace_ITVS_text = 'Profil_OsobitnyPostup_suvisiace_ITVS_text',
    Profil_OsobitnyPostup_suvisiace_PO_text = 'Profil_OsobitnyPostup_suvisiace_PO_text',
    Profil_Individuum_hasVersion = 'Profil_Individuum_hasVersion',
    Profil_Individuum_deferenceTarget = 'Profil_Individuum_deferenceTarget',
    Profil_Individuum_id_regex_pattern = 'Profil_Individuum_id_regex_pattern',
    Profil_Individuum_parameter_name = 'Profil_Individuum_parameter_name',
    Profil_Individuum_parameter_description = 'Profil_Individuum_parameter_description',
    Profil_Ontologia_uri_pre_ontologiu = 'Profil_Ontologia_uri_pre_ontologiu',
    Profil_Ontologia_historicky_kod = 'Profil_Ontologia_historicky_kod',
    Profil_VerziaOntologie_uri_distribucie = 'Profil_VerziaOntologie_uri_distribucie',
    Profil_VerziaOntologie_typ_media = 'Profil_VerziaOntologie_typ_media',
    Profil_VerziaOntologie_verzia = 'Profil_VerziaOntologie_verzia',
    Profil_VerziaOntologie_platne_od = 'Profil_VerziaOntologie_platne_od',
    Profil_VerziaOntologie_platne_do = 'Profil_VerziaOntologie_platne_do',
    Profil_VerziaOntologie_download_url = 'Profil_VerziaOntologie_download_url',
    EA_Profil_Projekt_metoda_riadenia_projektu = 'EA_Profil_Projekt_metoda_riadenia_projektu',
    EA_Profil_Projekt_faza_projektu = 'EA_Profil_Projekt_faza_projektu',
    EA_Profil_ISVS_modul_isvs = 'EA_Profil_ISVS_modul_isvs',
    EA_Profil_Formular_kod_formulara = 'EA_Profil_Formular_kod_formulara',
}

export enum ATTRIBUTE_PROFILE_NAME {
    Gen_Profil_EA = 'Gen_Profil_EA',
    Profil_KRIS = 'Profil_KRIS',
    Profil_Training = 'Profil_Skolenie',
    Financny_Profil_Projekt = 'Financny_Profil_Projekt',
    Integracia_Profil_Integracia = 'Integracia_Profil_Integracia',
    Profil_UPVS_je_genericka = 'KS_Profil_UPVS_je_genericka',
    Profil_ReferencnyAtribut = 'Profil_ReferencnyAtribut',
    Profil_ObjektEvidencie = 'Profil_ObjektEvidencie',
    Profil_AtributObjektuEvidencie = 'Profil_AtributObjektuEvidencie',
    Profil_OEOpravnenie = 'Profil_OEOpravnenie',
    Profil_OsobitnyPostup = 'Profil_OsobitnyPostup',
    EA_Profil_Projekt = 'EA_Profil_Projekt',
}

export enum API_STANDARD_REQUEST_ATTRIBUTES {
    name = 'name',
    description = 'description',
    placementProposal = 'placementProposal',
    legislativeTextProposal = 'legislativeTextProposal',
    financialImpact = 'financialImpact',
    securityImpact = 'securityImpact',
    privacyImpact = 'privacyImpact',
    workGroupId = 'workGroupId',
}

export const Gen_Profil = 'Gen_Profil'
export const Gui_Profil_Standardy = 'Gui_Profil_Standardy'

export const Gui_Profil_RR = 'Gui_Profil_RR'
export const Reference_Registers = 'ReferenceRegisters'
export const Reference_Identifiers = 'ReferenceIdentifiers'

export const TYPE_OF_APPROVAL_PROCESS_DEFAULT = 'OPTIONAL_APPROVAL'

export enum APPROVAL_PROCESS {
    OPTIONAL_APPROVAL = 'OPTIONAL_APPROVAL',
    WITHOUT_APPROVAL = 'WITHOUT_APPROVAL',
    MANDATORY_APPROVAL = 'MANDATORY_APPROVAL',
}

export enum PROJECT_STATE_ENUM {
    c_stav_projektu_1 = 'c_stav_projektu_1',
    c_stav_projektu_2 = 'c_stav_projektu_2',
    c_stav_projektu_3 = 'c_stav_projektu_3',
    c_stav_projektu_4 = 'c_stav_projektu_4',
    c_stav_projektu_5 = 'c_stav_projektu_5',
    c_stav_projektu_6 = 'c_stav_projektu_6',
    c_stav_projektu_7 = 'c_stav_projektu_7',
    c_stav_projektu_8 = 'c_stav_projektu_8',
    c_stav_projektu_9 = 'c_stav_projektu_9',
    c_stav_projektu_10 = 'c_stav_projektu_10',
    c_stav_projektu_11 = 'c_stav_projektu_11',
    c_stav_projektu_12 = 'c_stav_projektu_12',
    'c_stav_projektu_agile.1' = 'c_stav_projektu_agile.1',
    'c_stav_projektu_agile.2' = 'c_stav_projektu_agile.2',
    'c_stav_projektu_agile.3' = 'c_stav_projektu_agile.3',
    'c_stav_projektu_agile.4' = 'c_stav_projektu_agile.4',
    'c_stav_projektu_agile.5' = 'c_stav_projektu_agile.5',
    'c_stav_projektu_agile.6' = 'c_stav_projektu_agile.6',
    'c_stav_projektu_agile.7' = 'c_stav_projektu_agile.7',
    'c_stav_projektu_agile.8' = 'c_stav_projektu_agile.8',
    'c_stav_projektu_agile.9' = 'c_stav_projektu_agile.9',
    'c_stav_projektu_agile.10' = 'c_stav_projektu_agile.10',
    'c_stav_projektu_agile.11' = 'c_stav_projektu_agile.11',
    'c_stav_projektu_agile.12' = 'c_stav_projektu_agile.12',
}

export const VALIDATION_STATES = [
    PROJECT_STATE_ENUM.c_stav_projektu_4,
    PROJECT_STATE_ENUM.c_stav_projektu_11,
    PROJECT_STATE_ENUM['c_stav_projektu_agile.4'],
    PROJECT_STATE_ENUM['c_stav_projektu_agile.11'],
]

export enum STATUTAR_NAME {
    Profil_PO_statutar_titul_pred_menom = 'Profil_PO_statutar_titul_pred_menom',
    Profil_PO_statutar_meno = 'Profil_PO_statutar_meno',
    Profil_PO_statutar_priezvisko = 'Profil_PO_statutar_priezvisko',
    Profil_PO_statutar_titul_za_menom = 'Profil_PO_statutar_titul_za_menom',
}
export const TOP_LEVEL_PO_ICO = '000000003001'

export const MEASURE_UNIT = 'MERNA_JEDNOTKA'

export enum COOKIES_TYPES {
    NECESSARILY_COOKIES_CONSENT = 'necessarily_cookies_consent',
    ANALYTICALLY_COOKIES_CONSENT = 'analytically_cookies_consent',
    PREFERENTIAL_COOKIES_CONSENT = 'preferential_cookies_consent',
}

export enum CATEGORY_ENUM {
    NOT_VISIBLE = 'NO',
    READ_ONLY_BDA = 'ROCB',
    READ_ONLY = 'RONCB',
    READ_WRITE = 'RWNCB',
    READ_WRITE_BDA = 'RWCB',
}
export enum TYPES_ENUM {
    SYSTEM = 'system',
}
export const NOT_PUBLIC_ENTITIES = ['MiestoPrevadzky']

export const connectedCiTabsToRemove = [
    'BazaDat',
    'Migracia',
    'Platforma',
    'TP_SietovyPrvok',
    'SystemovySW',
    'TP_SpecTechnologia',
    'TP_Ulozisko',
    'TP_VypoctovyZdroj',
    'TP_Zalohovanie',
]

export enum QueryKeysByEntity {
    REFERENCE_REGISTER = 'referenceRegisterData',
    REFERENCE_REGISTERS = 'referenceRegistersList',
}

export enum Roles {
    SZC_HLGES = 'SZC_HLGES',
    SZC_VEDGES = 'SZC_VEDGES',
}

export const SlaViewOptions = [
    { value: 'true', label: 'Len aktívne záznamy' },
    { value: 'false', label: 'Všetky záznamy' },
]

export const TYP_HODNOTY = 'TYP_HODNOTY'
export const TYP_PARAMETROV_JEDNOTKA = 'TYP_PARAMETROV_JEDNOTKA'

export const SYSTEM_STATE = 'SYSTEM_STATE'
export const SYSTEM_STATE_COLOR = 'SYSTEM_STATE_COLOR'
export const SHOW_SYSTEM_STATE_BAR = 'show-system-state-bar'

export enum RELATION_TYPE {
    URIDataset_patri_URIKatalog = 'URIDataset_patri_URIKatalog',
    DatovyPrvok_sa_sklada_DatovyPrvok = 'DatovyPrvok_sa_sklada_DatovyPrvok',
    Individuum_je_typu_DatovyPrvok = 'Individuum_je_typu_DatovyPrvok',
    URIDataset_definuje_uri_ZC = 'URIDataset_definuje_uri_ZC',
    URIDataset_obsahuje_DatovyPrvok = 'URIDataset_obsahuje_DatovyPrvok',
    PO_je_gestor_DatovyPrvok = 'PO_je_gestor_DatovyPrvok',
    PO_je_gestor_URIKatalog = 'PO_je_gestor_URIKatalog',
    Ciselnik_je_podriadeny_Ciselnik = 'Ciselnik_je_podriadeny_Ciselnik',
    PO_je_podriadenou_PO = 'PO_je_podriadenou_PO',
    osobitny_postup_vztah_ISVS = 'osobitny_postup_vztah_ISVS',
    osobitny_postup_vztah_PO = 'osobitny_postup_vztah_PO',
    Objekt_evidencie_ma_atribut_evidencie = 'Objekt_evidencie_ma_atribut_evidencie',
    PO_poskytovatelom_KS = 'PO_poskytovatelom_KS',
    PO_je_poskytovatelom_KS = 'PO_je_poskytovatelom_KS',
    PO_je_poskytovatelom_OE = 'PO_je_poskytovatelom_OE',
    OE_patri_Agenda = 'OE_patri_Agenda',
    ISVS_je_poskytovatelom_AOE = 'ISVS_je_poskytovatelom_AOE',
    PO_je_poskytovatelom_AOE = 'PO_je_poskytovatelom_AOE',
    PO_ma_opravnenie = 'PO_ma_opravnenie',
    KRIS_stanovuje_Ciel = 'KRIS_stanovuje_Ciel',
    Projekt_realizuje_aktivitu = 'Projekt_realizuje_aktivitu',
    PO_je_gestor_Ontologia = 'PO_je_gestor_Ontologia',
    Ontologia_ma_verziu = 'Ontologia_ma_verziu',
    ISVS_patri_pod_ISVS = 'ISVS_patri_pod_ISVS',
    Program_financuje_Projekt = 'Program_financuje_Projekt',
}

export enum RefIdentifierTypeEnum {
    Individuum = 'Individuum',
    DatovyPrvok = 'DatovyPrvok',
    Ontologia = 'Ontologia',
    VerziaOntologie = 'VerziaOntologie',
}

export enum EntityColorEnum {
    customEntity = '#dfdfdf',
    systemEntity = '#ffe4af',
    businessLayer = '#ffffaf',
    appAndDataLayer = '#afffff',
    technologicalLayer = '#afffaf',
    implementationAndMigration = '#ffe0e0',
    implementationAndMigration2 = '#e0ffe0',
    motivationalEntity = '#ffccff',
    motivationalEntity2 = '#ccccff',
}

export const EntityColorEnumTranslateKeys = {
    [EntityColorEnum.customEntity]: 'customEntity',
    [EntityColorEnum.systemEntity]: 'systemEntity',
    [EntityColorEnum.businessLayer]: 'businessLayer',
    [EntityColorEnum.appAndDataLayer]: 'appAndDataLayer',
    [EntityColorEnum.technologicalLayer]: 'technologicalLayer',
    [EntityColorEnum.implementationAndMigration]: 'implementationAndMigration',
    [EntityColorEnum.implementationAndMigration2]: 'implementationAndMigration',
    [EntityColorEnum.motivationalEntity]: 'motivationalEntity',
    [EntityColorEnum.motivationalEntity2]: 'motivationalEntity',
}

export const PO_je_poskytovatelom_KS = 'PO_je_poskytovatelom_KS'
export const ALLOWED_FILE_TYPES = [
    '.txt',
    '.rtf',
    '.pdf',
    '.doc',
    '.docx',
    '.xcl',
    '.xclx',
    '.jpg',
    '.png',
    '.gif',
    '.csv',
    '.xlsx',
    '.odt',
    '.archimate',
    '.xml',
    '.ods',
    '.zip',
    '.7z',
    '.tar',
    '.tar.gz',
    '.tgz',
    '.gz',
    '.jpg',
    '.jpeg',
    '.jpe',
    '.jfif',
    '.jfi',
    '.jif',
    '.tif',
    '.tiff',
]

export const attributesFilter = [
    { name: ATTRIBUTE_NAME.Gen_Profil_RefID_stav_registracie, filterValue: [{ value: 'c_stav_registracie.2', equality: 'EQUAL' }] },
]

export const ZbierkaZakonov = 'c_zdroj_pravneho_zakladu.zz'

export const CREATE_RELATION_TYPE_BLACKLIST = [RELATION_TYPE.PO_ma_opravnenie, RELATION_TYPE.Program_financuje_Projekt]
export const CODELIST_LINK = 'https://data.gov.sk/set/codelist/'

export const KRIT_HODNOTENIE = 'krit_hodnotenie'
export const PROJEKT_STANOVISKO = 'projekt_stanovisko'
export const KRIT = 'KRIT'
export const CODELIST = 'codelist'
export const OTHER_ENTITY = 'otherEntity'

export const codeListStateFilterSearch = '?wfState--ilike=PUBLISHED'

export const MONITORING_UPLOAD_VALIDATION_ERRORS: { [key: string]: string } = {
    UNKNOWN_SERVICE_TYPE: 'UN_KNOW_SERVICE_TYPE',
    UNKNOWN_SERVICE: 'UN_KNOW_SERVICE',
    UNKNOWN_PARAMETER: 'UN_KNOW_PARAMETER',
    UNKNOWN_START_DATE: 'UN_KNOW_START_DATE',
    UNKNOWN_END_DATE: 'UN_KNOW_END_DATE',
    END_DATE_BOFORE_START: 'END_DATE_BEFORE_START',
    NOT_NUMBER: 'NOT_NUMBER',
    NEGATIVE_NUMBER: 'NEGATIVE_NUMBER',
    MISSING_VALUES: 'MISSING_VALUES',
    RIGHTS_ERROR: 'RIGHTS_ERROR',
    FILE_EMPTY: 'FILE_EMPTY',
    FILE_ERROR: 'FILE_ERROR',
}

export const keysToSkip = new Set<string>([
    FILTER_KEY.fullTextSearch,
    FILTER_KEY.attributeFilters,
    FILTER_KEY.sort,
    FILTER_KEY.metaAttributeFilters,
    FILTER_KEY.pageSize,
    FILTER_KEY.pageNumber,
    FILTER_KEY.state,
    FILTER_KEY.onlyFreePO,
    FILTER_KEY.relationFilters,
])

export const FINANCES_TYPES = {
    REAL: 'real',
    CORRECTED: 'corrected',
    ACCEPTED: 'accepted',
} as const

export type FINANCE_TYPE = (typeof FINANCES_TYPES)[keyof typeof FINANCES_TYPES]

export const RIS_EXPENSES_GAP = 10

export enum PROJECT_QUALIFIER_TYPE {
    WATERFALL = 'WATERFALL',
    AGILE = 'AGILE',
}

export const FEEDBACK_EMAIL = 'metais@mirri.gov.sk'
