import { QueryFeedback } from '@isdd/metais-common/index'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { MainContentWrapper } from '@/components/MainContentWrapper'

export const LogoutPage: React.FC = () => {
    const logoutURL =
        import.meta.env.VITE_REST_CLIENT_IAM_OIDC_BASE_URL +
        (import.meta.env.VITE_IAM_OIDC_PATH ? `/${import.meta.env.VITE_IAM_OIDC_PATH}/logout` : '/logout')

    const ref = useRef<HTMLFormElement>(null)

    const { t } = useTranslation()

    useEffect(() => {
        ref.current?.submit()
    }, [])

    return (
        <MainContentWrapper>
            <QueryFeedback loading withChildren indicatorProps={{ fullscreen: true, label: t('titles.logout') }}>
                <form ref={ref} action={logoutURL} method="POST" />
            </QueryFeedback>
        </MainContentWrapper>
    )
}
