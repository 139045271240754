import { CheckBox, IOption } from '@isdd/idsk-ui-kit/index'
import { ATTRIBUTE_NAME, RefIdentifierTypeEnum } from '@isdd/metais-common/api'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { Languages } from '@isdd/metais-common/localization/languages'
import { CellContext, ColumnDef, Row, Table } from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { Link } from 'react-router-dom'
import { CHECKBOX_CELL } from '@isdd/idsk-ui-kit/table/constants'
import { RouterRoutes } from '@isdd/metais-common/navigation/routeNames'

import { ColumnsOutputDefinition } from '@/componentHelpers/ci/ciTableHelpers'
import { PageType } from '@/components/containers/ref-identifiers/RefIdentifiersContainer'

export enum RefIdentifierListShowEnum {
    ALL = 'all',
    ONLY_MY = 'onlyMy',
}

export const refIdentifierTypeOptions = (t: TFunction): IOption<string>[] => {
    return [
        { value: RefIdentifierTypeEnum.DatovyPrvok, label: t(`refIdentifiers.type.${RefIdentifierTypeEnum.DatovyPrvok}`) },
        { value: RefIdentifierTypeEnum.Individuum, label: t(`refIdentifiers.type.${RefIdentifierTypeEnum.Individuum}`) },
        { value: RefIdentifierTypeEnum.Ontologia, label: t(`refIdentifiers.type.${RefIdentifierTypeEnum.Ontologia}`) },
        { value: RefIdentifierTypeEnum.VerziaOntologie, label: t(`refIdentifiers.type.${RefIdentifierTypeEnum.VerziaOntologie}`) },
    ]
}

export const refIdentifierStateOptions = (enumData: EnumType | undefined, language: string): IOption<string>[] => {
    return (
        enumData?.enumItems?.map((item) => ({ value: item.code ?? '', label: (language == Languages.SLOVAK ? item.value : item.engValue) ?? '' })) ||
        []
    )
}

export const refIdentifierViewOptions = (t: TFunction): IOption<string>[] => {
    return [
        { value: RefIdentifierListShowEnum.ALL, label: t(`refIdentifiers.filter.all`) },
        { value: RefIdentifierListShowEnum.ONLY_MY, label: t(`refIdentifiers.filter.onlyMy`) },
    ]
}

export const refIdentifierColumns = (
    t: TFunction,
    language: string,
    stateEnum: EnumType | undefined,
    user: boolean,
    pageType: PageType,
): Array<ColumnDef<ColumnsOutputDefinition>> => [
    ...(user
        ? [
              {
                  header: ({ table }: { table: Table<ColumnsOutputDefinition> }) => {
                      return (
                          <div className="govuk-checkboxes govuk-checkboxes--small">
                              <CheckBox
                                  label=""
                                  name="checkbox"
                                  id="checkbox-all"
                                  value="checkbox-all"
                                  onChange={table.getToggleAllRowsSelectedHandler()}
                                  onClick={(event) => event.stopPropagation()}
                                  checked={table.getIsAllRowsSelected()}
                                  aria-label={t('table.selectAllItems')}
                              />
                          </div>
                      )
                  },
                  id: CHECKBOX_CELL,
                  cell: ({ row }: { row: Row<ColumnsOutputDefinition> }) => (
                      <div className="govuk-checkboxes govuk-checkboxes--small">
                          <CheckBox
                              label=""
                              aria-label={t('table.selectItem', { itemName: row.original.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] })}
                              name="checkbox"
                              id={`checkbox_${row.id}`}
                              value="true"
                              onChange={row.getToggleSelectedHandler()}
                              onClick={(event) => event.stopPropagation()}
                              checked={row.getIsSelected()}
                          />
                      </div>
                  ),
              },
          ]
        : []),
    {
        id: 'technicalName',
        header: t('refIdentifiers.table.name'),
        accessorFn: (row) => row.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov],
        enableSorting: true,
        size: 150,
        meta: {
            getCellContext: (ctx: CellContext<ColumnsOutputDefinition, unknown>) => ctx.getValue(),
        },
        cell: (ctx) => (
            <Link
                to={
                    (pageType === 'requests' ? RouterRoutes.DATA_OBJECT_REF_IDENTIFIER_REQUESTS : RouterRoutes.DATA_OBJECT_REF_IDENTIFIERS_LIST) +
                    '/' +
                    ctx?.row?.original?.uuid
                }
                onClick={(e) => e.stopPropagation()}
            >
                {ctx?.getValue?.() as string}
            </Link>
        ),
    },
    {
        id: 'uri',
        header: t('refIdentifiers.table.uri'),
        size: 250,
        accessorFn: (row) => {
            if (row.type === RefIdentifierTypeEnum.DatovyPrvok) return row.attributes?.[ATTRIBUTE_NAME.Profil_DatovyPrvok_kod_datoveho_prvku]
            if (row.type === RefIdentifierTypeEnum.Individuum) return row.attributes?.[ATTRIBUTE_NAME.Profil_Individuum_zaklad_uri]
            if (row.type === RefIdentifierTypeEnum.Ontologia) return row.attributes?.[ATTRIBUTE_NAME.Profil_Ontologia_uri_pre_ontologiu]
            if (row.type === RefIdentifierTypeEnum.VerziaOntologie) return row.attributes?.[ATTRIBUTE_NAME.Profil_VerziaOntologie_uri_distribucie]
        },
        meta: {
            getCellContext: (ctx: CellContext<ColumnsOutputDefinition, unknown>) => ctx.getValue(),
        },
        cell: (ctx) => ctx.getValue(),
    },
    {
        id: 'type',
        header: t('refIdentifiers.table.type'),
        accessorFn: (row) => row.type,
        meta: {
            getCellContext: (ctx: CellContext<ColumnsOutputDefinition, unknown>) => ctx.getValue(),
        },
        cell: (ctx) => t(`refIdentifiers.type.${ctx.getValue()}`),
    },
    {
        id: 'state',
        header: t('refIdentifiers.table.state'),
        accessorFn: (row) => row.attributes?.[ATTRIBUTE_NAME.Gen_Profil_RefID_stav_registracie],
        meta: {
            getCellContext: (ctx: CellContext<ColumnsOutputDefinition, unknown>) => ctx.getValue(),
        },
        cell: (ctx) => {
            if (!ctx.getValue()) return ''
            const enumItem = stateEnum?.enumItems?.find((item) => item.code === ctx.getValue())
            if (!enumItem) return ''
            return language == Languages.SLOVAK ? enumItem.value ?? '' : enumItem.engValue ?? ''
        },
    },
]
